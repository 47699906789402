import { COMMISSION_LOG_ERROR_CODE, COMMISSION_STAGE } from "../util/constants";

export const CommissionErrorMixin = {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    mapPhaseNames(phase) {
      const _phase = Array.isArray(phase) ? phase : [phase];

      const map = {
        R: "L1",
        W: "L2",
        B: "L3",
      };
      if (!this.$store.getters.is2P && !this.$store.getters.isRWB) {
        return _phase
          .map((p) => {
            // R, W, B -> L1, L2, L3
            if (/^[RWB]$/.test(p)) {
              return map[p];
            }
            // R1, W1, B1 -> L1-1, L2-1, L3-1
            if (/^[RWB]\d$/.test(p)) {
              const [color, index] = p.split("");
              return `${map[color]}-${index}`;
            }
            return p;
          })
          .join(", ");
      } else {
        return _phase.join(", ");
      }
    },
    mapAddress(phaseName) {
      const map = {
        R: "L1",
        W: "L2",
        B: "L3",
      };
      const phase = phaseName.split(", ");
      return phase
        .map((p) => {
          if (/^[RWB][1-5]$/.test(p)) {
            const phase = map[p[0]];
            const connIdx = Number(p[1]);
            const address =
              this.$store.state.project.connections[connIdx - 1][phase];
            return `${address} (${p})`;
          }
          if (/^L[1-3]-[1-5]$/.test(p)) {
            const [phase, connIdx] = p.split("-");
            const address =
              this.$store.state.project.connections[connIdx - 1][phase];
            return `${address} (${p})`;
          }

          return p;
        })
        .join(", ");
    },
    async tryAgainClicked() {
      this.loading = true;
      await this.$store.dispatch("commission", {
        uploadConfig: this.$route.query.fail === COMMISSION_STAGE.FIRST,
      });
      this.loading = false;
    },
  },
  computed: {
    CTReversedUnits() {
      return this.$store.state.commissionLog.logs
        .filter(
          ({ errorcode }) => errorcode === COMMISSION_LOG_ERROR_CODE.REVERSED
        )
        .map(({ con_name }) => this.mapAddress(this.mapPhaseNames(con_name)));
    },
    CTNotReadingUnits() {
      return this.$store.state.commissionLog.logs
        .filter(({ errorcode }) =>
          [
            COMMISSION_LOG_ERROR_CODE.UNKNOWN,
            COMMISSION_LOG_ERROR_CODE.NO_GRID,
          ].includes(errorcode)
        )
        .map(({ con_name }) => this.mapAddress(this.mapPhaseNames(con_name)));
    },
    CTReadingIncorrectUnits() {
      return this.$store.state.commissionLog.logs
        .filter(({ errorcode }) =>
          [
            COMMISSION_LOG_ERROR_CODE.WRONG_CT,
            COMMISSION_LOG_ERROR_CODE.SWAPPED,
          ].includes(errorcode)
        )
        .map(({ con_name }) => this.mapAddress(this.mapPhaseNames(con_name)));
    },
  },
};
