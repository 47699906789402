<template>
  <div>
    <div class="title-wrapper">
      <div class="title">
        System <br />
        information
      </div>
    </div>
    <div class="content">
      <div class="indicator-wrapper">
        <div class="icon">
          <PowerIcon
            :color="
              isCritical() ? '#ff462c' : isNonCritical() ? '#ffe86c' : '#005149'
            "
          />
        </div>
        <div class="icon">
          <WifiIcon
            :color="wifiConnected ? '#005149' : '#ffe86c'"
            width="17"
            height="12"
          />
        </div>
        <div class="icon">
          <NetworkIcon :color="wifiConnected ? '#005149' : '#FFE86C'" />
        </div>
      </div>

      <div class="section1">
        <div class="item">
          <CircleIcon :opacity="0.7"><QRCode /></CircleIcon>
          <div class="text">
            <div class="label">SolShare serial number</div>
            <div class="value" data-tn="serial-number">
              {{ $store.state.project.serialNumber }}
            </div>
          </div>
          <div class="arrow"></div>
        </div>

        <div
          class="item"
          @click="
            () => {
              if (this.$store.state.systemInfo.meter.project) {
                this.$router.push('/view-building-address');
              } else {
                this.$message.warn(
                  'This Solshare is not linked with an valid address'
                );
              }
            }
          "
        >
          <CircleIcon :opacity="0.7"><Location /></CircleIcon>
          <div class="text" data-tn="building-address">
            <div class="label">Building address</div>
            <div
              v-if="$store.state.systemInfo.meter.project"
              class="value"
              data-tn="address-value"
            >
              {{ $store.state.systemInfo.meter.project.address }}
            </div>
          </div>
          <div class="arrow"><SmallArrow /></div>
        </div>

        <div class="item" @click="() => this.$router.push('/wifi-status')">
          <CircleIcon :opacity="0.7"><WifiIcon /></CircleIcon>
          <div class="text" data-tn="wifi">
            <div class="label">WiFi</div>
            <div class="value" data-tn="wifi-value">
              {{
                wifiConnected
                  ? `Connected to ${$store.state.wifiStatus.ssid}`
                  : `Last connected to ${$store.state.wifiStatus.ssid}`
              }}
            </div>
          </div>
          <div class="arrow"><SmallArrow /></div>
        </div>
      </div>

      <div class="divider" />

      <div class="section2">
        <div
          class="item"
          data-tn="solar-delivery-method"
          @click="() => this.$router.push('/view-solar-deliver-method')"
        >
          <CircleIcon :opacity="0.7"><Lightning /></CircleIcon>
          <div class="text">
            <div class="label">Solar delivery method</div>
            <div class="value" data-tn="solarDeliveryMethod">
              {{ solarDeliveryMethod }}
            </div>
          </div>
          <div class="arrow"><SmallArrow /></div>
        </div>

        <div
          class="item"
          data-tn="connected-units"
          @click="() => this.$router.push('/view-connected-units')"
        >
          <CircleIcon :opacity="0.55"><AccountTree /></CircleIcon>
          <div class="text">
            <div class="label">Connected units</div>
            <div class="value">View or edit unit naming</div>
          </div>
          <div class="arrow"><SmallArrow /></div>
        </div>

        <div class="item" @click="() => this.$router.push('/fault-status')">
          <CircleIcon :opacity="0.3"><Info /></CircleIcon>
          <div class="text" data-tn="fault-status">
            <div class="label">Fault Status</div>
            <div class="value" data-tn="fault-status-value">
              {{ displayFaults().length }} faults
            </div>
          </div>
          <div class="arrow">
            <a-badge
              class="badge"
              :count="displayFaults().length"
              :number-style="counterStyle"
            />
            <SmallArrow />
          </div>
        </div>
      </div>

      <div class="divider" />

      <div class="section3">
        <div
          class="item"
          @click="
            () =>
              this.$router.push({
                path: '/solshares-at-site',
                query: { from: 'system-info' },
              })
          "
        >
          <CircleIcon :opacity="0.7">
            <Apartment />
          </CircleIcon>
          <div class="text" data-tn="solshare-at-site">
            <div class="label">SolShare(s) at Site</div>
            <div class="value" data-tn="solshare-at-site-value">
              {{ $store.state.systemInfo.solsharesAtSite.length }} SolShares,
              {{ solsharesAtSiteFailed }}
              with faults
            </div>
          </div>
          <div class="arrow">
            <a-badge
              class="badge"
              :count="solsharesAtSiteFailed"
              :number-style="counterStyle"
            />
            <SmallArrow />
          </div>
        </div>

        <div class="item">
          <CircleIcon :opacity="0.55"><Person /></CircleIcon>
          <div class="text">
            <div class="label">Installer</div>
            <div
              v-if="$store.state.systemInfo.meter.commissioner"
              class="value"
              data-tn="installer-value"
            >
              {{ $store.state.systemInfo.meter.commissioner.name }},
              {{ $store.state.systemInfo.meter.commissioner.company }}
            </div>
            <div v-else class="value">N/A</div>
          </div>
          <div class="arrow"></div>
        </div>

        <div class="item" @click="() => this.$router.push('/view-fleet')">
          <CircleIcon :opacity="0.55"><Fleet /></CircleIcon>
          <div class="text" data-tn="view-fleet">
            <div class="label">View fleet</div>
            <div class="value" data-tn="view-fleet-value">
              {{ `${$store.state.fleet.length} Sites` }}
            </div>
          </div>
          <div class="arrow"><SmallArrow /></div>
        </div>

        <div
          class="item access-control"
          @click="
            () => {
              if (this.$store.state.systemInfo.meter.project) {
                this.$router.push('/access-control');
              } else {
                this.$message.warn(
                  'This Solshare is not linked with an valid address'
                );
              }
            }
          "
        >
          <CircleIcon :opacity="0.55">
            <a-icon type="lock" style="color: white" />
          </CircleIcon>
          <div class="text">
            <div class="label">Access Control</div>
          </div>
          <div class="arrow"><SmallArrow /></div>
        </div>

        <div class="item">
          <CircleIcon :opacity="0.3"><Document /></CircleIcon>
          <div class="text">
            <div class="label">Software version</div>
            <div class="value" data-tn="swv-value">
              {{ $store.state.systemInfo.meter.softwareVersion || "N/A" }}
            </div>
          </div>
          <div class="arrow"></div>
        </div>

        <div class="item">
          <CircleIcon :opacity="0.3"><Layers /></CircleIcon>
          <div class="text">
            <div class="label">Firmware version</div>
            <div class="value" data-tn="fwv-value">
              {{ $store.state.systemInfo.meter.firmwareVersion || "N/A" }}
            </div>
          </div>
          <div class="arrow"></div>
        </div>
      </div>

      <div class="contact" @click="() => this.$router.push('/contact')">
        <CircleIcon
          :opacity="1"
          color="#24BA80"
          :size="32"
          class="icon"
          shadow="0 2px 3px 0 rgba(0,0,0,0.16)"
        >
          <PhoneWhite />
        </CircleIcon>
        <div class="text">We're here to help</div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallArrow from "../assets/svg/small-arrow.svg";
import QRCode from "../assets/svg/qrcode-small.svg";
import Location from "../assets/svg/location.svg";
import Lightning from "../assets/svg/lightning.svg";
import AccountTree from "../assets/svg/account-tree.svg";
import Info from "../assets/svg/info.svg";
import Apartment from "../assets/svg/apartment.svg";
import Person from "../assets/svg/person.svg";
import Document from "../assets/svg/document.svg";
import Layers from "../assets/svg/layers.svg";
import PhoneWhite from "../assets/svg/phone-white.svg";
import Fleet from "../assets/svg/fleet-white.svg";
import { CircleIcon } from "../components/Icons/CircleIcon";
import WifiIcon from "../components/Icons/WiFi";
import PowerIcon from "../components/Icons/Power";
import NetworkIcon from "../components/Icons/Network";
import { ALGORITHM_TYPE, SOLAR_DELIVERY_METHOD } from "../util/constants";
import { faultMixin } from "../mixins/faults";

export default {
  name: "SystemInfo",
  components: {
    SmallArrow,
    QRCode,
    Location,
    WifiIcon,
    PowerIcon,
    NetworkIcon,
    Lightning,
    AccountTree,
    Info,
    Apartment,
    Person,
    Document,
    Layers,
    CircleIcon,
    PhoneWhite,
    Fleet,
  },
  mixins: [faultMixin],
  data() {
    return {
      counterStyle: {
        backgroundColor: "#FFE86C",
        color: "#000",
        boxShadow: "none",
      },
    };
  },
  computed: {
    solsharesAtSiteFailed() {
      return this.$store.state.systemInfo.solsharesAtSite.filter(
        ({ criticalFaults, nonCriticalFaults }) =>
          [...nonCriticalFaults, ...criticalFaults].length > 0
      ).length;
    },
    solarDeliveryMethod() {
      switch (this.$store.state.systemInfo.config.algorithm_type) {
        case SOLAR_DELIVERY_METHOD.NET_METERING:
        case SOLAR_DELIVERY_METHOD.OPTIMISED_AND_EVEN:
        case SOLAR_DELIVERY_METHOD.UNEQUAL_ALLOCATION:
          return "Custom allocation";
        case SOLAR_DELIVERY_METHOD.FULLY_OPTIMISED:
          return "Demand-based delivery";
        default:
          return "N/A";
      }
    },
    wifiConnected() {
      return this.$store.state.wifiStatus.connected;
    },
  },
};
</script>

<style lang="less" scoped>
.title-wrapper {
  background: #005149;
  height: 200px;
  color: #fff;
  font-size: 35px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 21px 29px;

  .title {
    line-height: 35px;
    margin-top: 80px;
  }
}
.content {
  background: #f5f5f5;
  min-height: calc(100vh - 270px);
  padding: 22px 22px 0 22px;

  .divider {
    height: 1px;
    width: 100%;
    background: #ccc;
  }

  .indicator-wrapper {
    height: 50px;
    background: rgba(157, 157, 157, 0.2);
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 50px;
    margin-bottom: 21px;

    .icon {
      background: rgba(255, 255, 255, 0.47);
      box-shadow: 0 0 11px 0 #ffffff;
      height: 28px;
      width: 28px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .section1 {
    height: 288px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 25px;

    .item {
      border-bottom: 1px solid #ccc;
      height: 96px;

      .label {
        color: #005149;
      }

      .value {
        color: #24ba80;
      }
    }
  }

  .section2,
  .section3 {
    margin: 30px 0;

    .label {
      color: #000;
    }

    .value {
      color: #9d9d9d;
    }
  }

  .item {
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 18px 21px 16px;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    .text {
      font-size: 17px;
      flex: 1;
      margin: 0 5px 0 20px;
      overflow: hidden;

      .label {
        margin-bottom: 8px;
      }
      .value {
        font-family: "Helvetica Light", sans-serif;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .arrow {
      display: flex;
      align-items: center;

      .badge {
        margin-right: 10px;
      }
    }
  }

  .contact {
    height: 91px;
    width: 88%;
    background: #fff;
    color: #24ba80;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    font-size: 27px;
    margin: 0 auto;
    font-family: "Helvetica Light", sans-serif;
    padding-top: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;

    .icon {
      position: absolute;
      top: -16px;
    }

    .text {
      margin-top: 10px;
    }
  }
}
</style>
