<template>
  <div class="content">
    <progress-bar :percent="60" />

    <IndexFingerSVG class="finder-icon" />

    <div class="info-text">
      It’s important that the correct delivery method is uploaded. Please
      confirm with your company which delivery is required and select from the
      below.
    </div>

    <white-button
      data-tn="custom-allocation"
      bordered
      class="white-button"
      :to="
        $store.getters.isUnequalAlgorithm
          ? '/commission/unequal-allocation-review'
          : '/commission/ct-rating'
      "
      @click="() => methodClicked(ALGORITHM_TYPE.CUSTOM_ALLOCATION)"
    >
      CUSTOM ALLOCATION
    </white-button>

    <white-button
      v-if="!$store.getters.is2P"
      data-tn="demand-based-delivery"
      bordered
      class="white-button"
      to="/commission/ct-rating"
      @click="() => methodClicked(ALGORITHM_TYPE.DEMAND_BASED_DELIVERY)"
    >
      DEMAND-BASED DELIVERY
    </white-button>

    <back-button />
  </div>
</template>

<script>
import IndexFingerSVG from "../assets/svg/index-finger.svg";
import { solarDeliveryMethodMixin } from "../mixins/solarDeliveryMethod";
export default {
  components: { IndexFingerSVG },
  mixins: [solarDeliveryMethodMixin],
};
</script>

<style lang="less" scoped>
.content {
  .finder-icon {
    margin-top: 33px;
    margin-bottom: 14px;
  }

  .info-text {
    margin-bottom: 27px;
  }

  .white-button {
    margin-bottom: 13px;
  }
}
</style>
