<template>
  <div class="content">
    <p>The inverter did not output required power within the test time.</p>
    <p>Minimum generation required: 200W</p>
    <ul>
      <li>Confirm all isolation switches are ON.</li>
      <li>Confirm inverter is commissioned and operational.</li>
      <li>
        Confirm SolShare providing inverter with correct voltage on all phases.
      </li>
      <li>
        Confirm inverter connected to SolShare as per the installation manual.
      </li>
      <li>Check weather suitability for PV generation</li>
    </ul>

    <p>
      <b>Note</b>: SolShare requires a minimum output from the inverter to
      commission the system. If there is insufficient PV available commissioning
      will not be possible at this time.
    </p>

    <yellow-button
      class="troubleshoot"
      next-text="Fixed? Re-try commissioning"
      data-tn="retry"
      @click="tryAgainClicked"
    >
      Fixed? Re-try commissioning
    </yellow-button>
  </div>
</template>

<script>
import { CommissionErrorMixin } from "../../../../mixins/commissionError";

export default {
  mixins: [CommissionErrorMixin],
};
</script>

<style scoped lang="less">
.content {
  margin-top: 30px;

  .troubleshoot {
    margin-top: 100px;
  }
}
</style>
