var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('div', {
    staticClass: "p"
  }, [_vm._v("Note the arrow markings on the base of the CT")]), _c('img', {
    attrs: {
      "src": require("../../../../../assets/png/ct-orientation.png")
    }
  }), _c('div', {
    staticClass: "p"
  }, [_vm._v(" Connection to check: "), _c('ul', {
    staticClass: "p"
  }, _vm._l(_vm.CTNotReadingUnits, function (unit, i) {
    return _c('li', {
      key: i
    }, [_vm._v(_vm._s(unit))]);
  }), 0)]), _c('back-and-next', {
    attrs: {
      "next-text": "Next - CT Installation",
      "to": "/commission/error/ct-not-reading-uk/4",
      "data-tn": "ct-not-reading-uk/3"
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p"
  }, [_c('b', [_vm._v("At the point of CT install")]), _vm._v(" ensure that the arrow is pointing to the load (flat). ")]);

}]

export { render, staticRenderFns }