var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "indicator-wrapper"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('PowerIcon', {
    attrs: {
      "color": _vm.isCritical() ? '#ff462c' : _vm.isNonCritical() ? '#ffe86c' : '#005149'
    }
  })], 1), _c('div', {
    staticClass: "icon"
  }, [_c('WifiIcon', {
    attrs: {
      "color": _vm.wifiConnected ? '#005149' : '#ffe86c',
      "width": "17",
      "height": "12"
    }
  })], 1), _c('div', {
    staticClass: "icon"
  }, [_c('NetworkIcon', {
    attrs: {
      "color": _vm.wifiConnected ? '#005149' : '#FFE86C'
    }
  })], 1)]), _c('div', {
    staticClass: "section1"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('CircleIcon', {
    attrs: {
      "opacity": 0.7
    }
  }, [_c('QRCode')], 1), _c('div', {
    staticClass: "text"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("SolShare serial number")]), _c('div', {
    staticClass: "value",
    attrs: {
      "data-tn": "serial-number"
    }
  }, [_vm._v(" " + _vm._s(_vm.$store.state.project.serialNumber) + " ")])]), _c('div', {
    staticClass: "arrow"
  })], 1), _c('div', {
    staticClass: "item",
    on: {
      "click": function () {
        if (_this.$store.state.systemInfo.meter.project) {
          _this.$router.push('/view-building-address');
        } else {
          _this.$message.warn('This Solshare is not linked with an valid address');
        }
      }
    }
  }, [_c('CircleIcon', {
    attrs: {
      "opacity": 0.7
    }
  }, [_c('Location')], 1), _c('div', {
    staticClass: "text",
    attrs: {
      "data-tn": "building-address"
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Building address")]), _vm.$store.state.systemInfo.meter.project ? _c('div', {
    staticClass: "value",
    attrs: {
      "data-tn": "address-value"
    }
  }, [_vm._v(" " + _vm._s(_vm.$store.state.systemInfo.meter.project.address) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "arrow"
  }, [_c('SmallArrow')], 1)], 1), _c('div', {
    staticClass: "item",
    on: {
      "click": function () {
        return _this.$router.push('/wifi-status');
      }
    }
  }, [_c('CircleIcon', {
    attrs: {
      "opacity": 0.7
    }
  }, [_c('WifiIcon')], 1), _c('div', {
    staticClass: "text",
    attrs: {
      "data-tn": "wifi"
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("WiFi")]), _c('div', {
    staticClass: "value",
    attrs: {
      "data-tn": "wifi-value"
    }
  }, [_vm._v(" " + _vm._s(_vm.wifiConnected ? `Connected to ${_vm.$store.state.wifiStatus.ssid}` : `Last connected to ${_vm.$store.state.wifiStatus.ssid}`) + " ")])]), _c('div', {
    staticClass: "arrow"
  }, [_c('SmallArrow')], 1)], 1)]), _c('div', {
    staticClass: "divider"
  }), _c('div', {
    staticClass: "section2"
  }, [_c('div', {
    staticClass: "item",
    attrs: {
      "data-tn": "solar-delivery-method"
    },
    on: {
      "click": function () {
        return _this.$router.push('/view-solar-deliver-method');
      }
    }
  }, [_c('CircleIcon', {
    attrs: {
      "opacity": 0.7
    }
  }, [_c('Lightning')], 1), _c('div', {
    staticClass: "text"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Solar delivery method")]), _c('div', {
    staticClass: "value",
    attrs: {
      "data-tn": "solarDeliveryMethod"
    }
  }, [_vm._v(" " + _vm._s(_vm.solarDeliveryMethod) + " ")])]), _c('div', {
    staticClass: "arrow"
  }, [_c('SmallArrow')], 1)], 1), _c('div', {
    staticClass: "item",
    attrs: {
      "data-tn": "connected-units"
    },
    on: {
      "click": function () {
        return _this.$router.push('/view-connected-units');
      }
    }
  }, [_c('CircleIcon', {
    attrs: {
      "opacity": 0.55
    }
  }, [_c('AccountTree')], 1), _vm._m(1), _c('div', {
    staticClass: "arrow"
  }, [_c('SmallArrow')], 1)], 1), _c('div', {
    staticClass: "item",
    on: {
      "click": function () {
        return _this.$router.push('/fault-status');
      }
    }
  }, [_c('CircleIcon', {
    attrs: {
      "opacity": 0.3
    }
  }, [_c('Info')], 1), _c('div', {
    staticClass: "text",
    attrs: {
      "data-tn": "fault-status"
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Fault Status")]), _c('div', {
    staticClass: "value",
    attrs: {
      "data-tn": "fault-status-value"
    }
  }, [_vm._v(" " + _vm._s(_vm.displayFaults().length) + " faults ")])]), _c('div', {
    staticClass: "arrow"
  }, [_c('a-badge', {
    staticClass: "badge",
    attrs: {
      "count": _vm.displayFaults().length,
      "number-style": _vm.counterStyle
    }
  }), _c('SmallArrow')], 1)], 1)]), _c('div', {
    staticClass: "divider"
  }), _c('div', {
    staticClass: "section3"
  }, [_c('div', {
    staticClass: "item",
    on: {
      "click": function () {
        return _this.$router.push({
          path: '/solshares-at-site',
          query: {
            from: 'system-info'
          }
        });
      }
    }
  }, [_c('CircleIcon', {
    attrs: {
      "opacity": 0.7
    }
  }, [_c('Apartment')], 1), _c('div', {
    staticClass: "text",
    attrs: {
      "data-tn": "solshare-at-site"
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("SolShare(s) at Site")]), _c('div', {
    staticClass: "value",
    attrs: {
      "data-tn": "solshare-at-site-value"
    }
  }, [_vm._v(" " + _vm._s(_vm.$store.state.systemInfo.solsharesAtSite.length) + " SolShares, " + _vm._s(_vm.solsharesAtSiteFailed) + " with faults ")])]), _c('div', {
    staticClass: "arrow"
  }, [_c('a-badge', {
    staticClass: "badge",
    attrs: {
      "count": _vm.solsharesAtSiteFailed,
      "number-style": _vm.counterStyle
    }
  }), _c('SmallArrow')], 1)], 1), _c('div', {
    staticClass: "item"
  }, [_c('CircleIcon', {
    attrs: {
      "opacity": 0.55
    }
  }, [_c('Person')], 1), _c('div', {
    staticClass: "text"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Installer")]), _vm.$store.state.systemInfo.meter.commissioner ? _c('div', {
    staticClass: "value",
    attrs: {
      "data-tn": "installer-value"
    }
  }, [_vm._v(" " + _vm._s(_vm.$store.state.systemInfo.meter.commissioner.name) + ", " + _vm._s(_vm.$store.state.systemInfo.meter.commissioner.company) + " ")]) : _c('div', {
    staticClass: "value"
  }, [_vm._v("N/A")])]), _c('div', {
    staticClass: "arrow"
  })], 1), _c('div', {
    staticClass: "item",
    on: {
      "click": function () {
        return _this.$router.push('/view-fleet');
      }
    }
  }, [_c('CircleIcon', {
    attrs: {
      "opacity": 0.55
    }
  }, [_c('Fleet')], 1), _c('div', {
    staticClass: "text",
    attrs: {
      "data-tn": "view-fleet"
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("View fleet")]), _c('div', {
    staticClass: "value",
    attrs: {
      "data-tn": "view-fleet-value"
    }
  }, [_vm._v(" " + _vm._s(`${_vm.$store.state.fleet.length} Sites`) + " ")])]), _c('div', {
    staticClass: "arrow"
  }, [_c('SmallArrow')], 1)], 1), _c('div', {
    staticClass: "item access-control",
    on: {
      "click": function () {
        if (_this.$store.state.systemInfo.meter.project) {
          _this.$router.push('/access-control');
        } else {
          _this.$message.warn('This Solshare is not linked with an valid address');
        }
      }
    }
  }, [_c('CircleIcon', {
    attrs: {
      "opacity": 0.55
    }
  }, [_c('a-icon', {
    staticStyle: {
      "color": "white"
    },
    attrs: {
      "type": "lock"
    }
  })], 1), _vm._m(2), _c('div', {
    staticClass: "arrow"
  }, [_c('SmallArrow')], 1)], 1), _c('div', {
    staticClass: "item"
  }, [_c('CircleIcon', {
    attrs: {
      "opacity": 0.3
    }
  }, [_c('Document')], 1), _c('div', {
    staticClass: "text"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Software version")]), _c('div', {
    staticClass: "value",
    attrs: {
      "data-tn": "swv-value"
    }
  }, [_vm._v(" " + _vm._s(_vm.$store.state.systemInfo.meter.softwareVersion || "N/A") + " ")])]), _c('div', {
    staticClass: "arrow"
  })], 1), _c('div', {
    staticClass: "item"
  }, [_c('CircleIcon', {
    attrs: {
      "opacity": 0.3
    }
  }, [_c('Layers')], 1), _c('div', {
    staticClass: "text"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Firmware version")]), _c('div', {
    staticClass: "value",
    attrs: {
      "data-tn": "fwv-value"
    }
  }, [_vm._v(" " + _vm._s(_vm.$store.state.systemInfo.meter.firmwareVersion || "N/A") + " ")])]), _c('div', {
    staticClass: "arrow"
  })], 1)]), _c('div', {
    staticClass: "contact",
    on: {
      "click": function () {
        return _this.$router.push('/contact');
      }
    }
  }, [_c('CircleIcon', {
    staticClass: "icon",
    attrs: {
      "opacity": 1,
      "color": "#24BA80",
      "size": 32,
      "shadow": "0 2px 3px 0 rgba(0,0,0,0.16)"
    }
  }, [_c('PhoneWhite')], 1), _c('div', {
    staticClass: "text"
  }, [_vm._v("We're here to help")])], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title-wrapper"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" System "), _c('br'), _vm._v(" information ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Connected units")]), _c('div', {
    staticClass: "value"
  }, [_vm._v("View or edit unit naming")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Access Control")])]);

}]

export { render, staticRenderFns }