var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('p', [_vm._v("The inverter did not output required power within the test time.")]), _c('p', [_vm._v("Minimum generation required: 200W")]), _vm._m(0), _vm._m(1), _c('yellow-button', {
    staticClass: "troubleshoot",
    attrs: {
      "next-text": "Fixed? Re-try commissioning",
      "data-tn": "retry"
    },
    on: {
      "click": _vm.tryAgainClicked
    }
  }, [_vm._v(" Fixed? Re-try commissioning ")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_c('li', [_vm._v("Confirm all isolation switches are ON.")]), _c('li', [_vm._v("Confirm inverter is commissioned and operational.")]), _c('li', [_vm._v(" Confirm SolShare providing inverter with correct voltage on all phases. ")]), _c('li', [_vm._v(" Confirm inverter connected to SolShare as per the installation manual. ")]), _c('li', [_vm._v("Check weather suitability for PV generation")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('b', [_vm._v("Note")]), _vm._v(": SolShare requires a minimum output from the inverter to commission the system. If there is insufficient PV available commissioning will not be possible at this time. ")]);

}]

export { render, staticRenderFns }