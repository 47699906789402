export function isNumber(rule, value, callback) {
  if (isNaN(Number(value))) {
    callback(new Error("please input a number"));
  } else {
    callback();
  }
}

export function isPositiveNumber(rule, value, callback) {
  if (isNaN(Number(value))) {
    callback(new Error("please input a number"));
  } else if (Number(value) <= 0) {
    callback(new Error("please input a positive number"));
  } else {
    callback();
  }
}

export function validateSystemSize(value, callback, showWarningCallback) {
  if (isNaN(Number(value))) {
    callback(new Error("please input a number"));
    return;
  }

  if (Number(value) <= 0) {
    callback(new Error("please input a positive number"));
    return;
  }

  const numValue = Number(value);
  if (numValue > 100) {
    callback(
      new Error("This number is too large, please remember values are in kW")
    );
    return;
  }

  if (numValue > 22) {
    showWarningCallback();
  }

  callback();
}
