var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _vm._m(1), _c('img', {
    staticClass: "measurement",
    attrs: {
      "src": require("../../../../assets/png/phase-phase-measurement.png")
    }
  }), _c('back-and-next', {
    staticClass: "troubleshoot",
    attrs: {
      "next-text": "Fixed? Re-try commissioning",
      "data-tn": "retry",
      "next-on-click": _vm.tryAgainClicked
    }
  }, [_vm._v(" Fixed? Re-try commissioning ")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('b', [_vm._v("How to check the phase wiring at the SolShare. ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ol', [_c('li', [_vm._v(" Ensure SolShare cover is open and secure in the \"Connection Position\" ")]), _c('li', [_vm._v("Set multi-meter to measure AC voltage")]), _c('li', [_vm._v("Hold one probe on a connection wired to L1 phase")]), _c('li', [_vm._v("Hold the other probe to another connection on L1")]), _c('li', [_vm._v(" Observe the voltage reading. It should read 0V. "), _c('b', [_vm._v("If this is not 0V then you may have a phase miswiring")])]), _c('li', [_vm._v("Repeat this for all connections across the same phase (L1)")]), _c('li', [_vm._v(" Similarly, repeat steps 3 - 6 for connections across L2 and L3 phases ")])]);

}]

export { render, staticRenderFns }