<template>
  <div class="content">
    <div class="p">
      <b>At the point of CT install</b> ensure that the arrow is pointing to the
      load.
    </div>

    <div class="p">For connections listed below reverse the CT.</div>
    <div class="p">Note the arrow markings on the base of the clip CT</div>

    <div class="p">
      Connection to check:
      <ul class="p">
        <li v-for="(unit, i) in CTReversedUnits" :key="i">{{ unit }}</li>
      </ul>
    </div>

    <img src="../../../../../assets/png/ct-orientation.png" />

    <back-and-next
      next-text="Next - At Solshare"
      to="/commission/error/reversed-ct-au/3"
      data-tn="reversed-ct-au/2"
    >
    </back-and-next>
  </div>
</template>

<script>
import { CommissionErrorMixin } from "../../../../../mixins/commissionError";

export default {
  mixins: [CommissionErrorMixin],
};
</script>

<style scoped lang="less">
.content {
  .p {
    margin-top: 20px;
  }

  img {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
