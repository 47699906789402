<template>
  <div class="content">
    <p>
      <b>How to fix a connection with no voltage reference </b>
    </p>
    <p>
      There must be grid voltage measured at the assigned SolShare input
      terminal.
    </p>
    <p>
      Measure for Voltage to Neutral of the incorrect connection. If zero, check
      all isolator state(s) between the SolShare and the grid connection point.
    </p>

    <back-and-next
      class="troubleshoot"
      next-text="Fixed? Re-try commissioning"
      data-tn="retry"
      :next-on-click="tryAgainClicked"
    >
      Fixed? Re-try commissioning
    </back-and-next>
  </div>
</template>

<script>
import { CommissionErrorMixin } from "../../../../mixins/commissionError";

export default {
  mixins: [CommissionErrorMixin],
};
</script>

<style scoped lang="less">
.content {
  margin-top: 30px;
}
</style>
