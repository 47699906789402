var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "p"
  }, [_vm._v("How to troubleshoot a CT that is reading incorrectly")]), _vm._m(0), _c('div', {
    staticClass: "p"
  }, [_vm._v(" Connection to check: "), _c('ul', {
    staticClass: "p"
  }, _vm._l(_vm.CTReadingIncorrectUnits, function (unit, i) {
    return _c('li', {
      key: i
    }, [_vm._v(" " + _vm._s(unit) + " ")]);
  }), 0)]), _c('back-and-next', {
    attrs: {
      "next-text": "Next - CT Installation",
      "data-tn": "ct-reading-incorrect-uk/1",
      "to": "/commission/error/ct-reading-incorrect-uk/2"
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p"
  }, [_vm._v(" Each CT must be "), _c('ul', {
    staticClass: "p"
  }, [_c('li', [_vm._v("Clipped around the correct cable at the meter")]), _c('li', [_vm._v("Clipped in the correct orientation at the meter")]), _c('li', [_vm._v("Correctly fitted to the SolShare connector")]), _c('li', [_vm._v("Match the current rating selected during setup")]), _c('li', [_vm._v(" If extended CT, good electrical contact at intermediate connection ")])])]);

}]

export { render, staticRenderFns }