var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('p', [_vm._v(" Please ensure all isolation points are switched on and the flat is grid connected (if pre-paid meter ensure there is credit on meter) ")]), _c('p', [_vm._v(" To isolate location of issue, measure voltage: At SolShare flat inputs to earth. Maintenance isolation board MCBs to earth. ")]), _c('back-and-next', {
    staticClass: "troubleshoot",
    attrs: {
      "next-text": "Fixed? Re-try commissioning",
      "data-tn": "retry",
      "next-on-click": _vm.tryAgainClicked
    }
  }, [_vm._v(" Fixed? Re-try commissioning ")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('b', [_vm._v("How to fix a connection with no voltage reference")])]);

}]

export { render, staticRenderFns }