import { render, staticRenderFns } from "./NoVoltageReference.UK.2.vue?vue&type=template&id=01314b72&scoped=true&"
import script from "./NoVoltageReference.UK.2.vue?vue&type=script&lang=js&"
export * from "./NoVoltageReference.UK.2.vue?vue&type=script&lang=js&"
import style0 from "./NoVoltageReference.UK.2.vue?vue&type=style&index=0&id=01314b72&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01314b72",
  null
  
)

export default component.exports