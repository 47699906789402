<template>
  <div class="content">
    <p>
      We have detected that there are problems with the voltage readings of this
      SolShare.
    </p>
    <p>The connections below are not reading voltage correctly:</p>
    <ul>
      <li v-for="(con, idx) of conName" :key="idx">{{ con }}</li>
    </ul>
    <p>Click through for guidance on how to check each connection.</p>

    <yellow-button class="troubleshoot" to="/commission/error/no-voltage-au">
      Troubleshoot
    </yellow-button>
  </div>
</template>

<script>
import { CommissionErrorMixin } from "../../../../mixins/commissionError";

export default {
  mixins: [CommissionErrorMixin],
  computed: {
    conName() {
      return this.$store.state.commissionLog.logs.map(({ con_name }) =>
        this.mapAddress(this.mapPhaseNames(con_name))
      );
    },
  },
};
</script>

<style scoped lang="less">
.content {
  margin-top: 30px;

  .troubleshoot {
    margin-top: 100px;
  }
}
</style>
