<template>
  <div class="content">
    <div class="p">
      <b>At the SolShare</b> CT connector check the polarity of the CT cables
      for each of the connections listed below.
    </div>

    <div class="p">
      Note: If extended, the polarity at the point of extension must also be
      checked.
    </div>

    <div class="p">
      Connection to check:
      <ul class="p">
        <li v-for="(unit, i) in CTReversedUnits" :key="i">{{ unit }}</li>
      </ul>
    </div>

    <img src="../../../../../assets/png/ct-connector.jpg" />

    <back-and-next
      next-text="Return to results"
      to="/commission/20"
      data-tn="reversed-ct-uk/3"
    >
    </back-and-next>
  </div>
</template>

<script>
import { CommissionErrorMixin } from "../../../../../mixins/commissionError";

export default {
  mixins: [CommissionErrorMixin],
};
</script>

<style scoped lang="less">
.content {
  .p {
    margin-top: 20px;
  }

  img {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
