<template>
  <div class="content">
    <div class="p">How to troubleshoot a CT that is reading incorrectly</div>

    <div class="p">
      Each CT must be
      <ul class="p">
        <li>Clipped around the correct cable at the meter</li>
        <li>Clipped in the correct orientation at the meter</li>
        <li>Correctly fitted to the SolShare connector</li>
        <li>Match the current rating selected during setup</li>
        <li>
          If extended CT, good electrical contact at intermediate connection
        </li>
      </ul>
    </div>

    <div class="p">
      Connection to check:
      <ul class="p">
        <li v-for="(unit, i) in CTReadingIncorrectUnits" :key="i">
          {{ unit }}
        </li>
      </ul>
    </div>

    <back-and-next
      next-text="Next - CT Installation"
      data-tn="ct-reading-incorrect-au/1"
      to="/commission/error/ct-reading-incorrect-au/2"
    >
    </back-and-next>
  </div>
</template>

<script>
import { CommissionErrorMixin } from "../../../../../mixins/commissionError";

export default {
  mixins: [CommissionErrorMixin],
};
</script>

<style scoped lang="less">
.content {
  .p {
    margin-top: 20px;
  }
}
</style>
