<template>
  <div class="content">
    <back-button />
    <div class="title">
      {{
        $store.state.wifiStatus.connected
          ? "Connected to:"
          : "Last connected to:"
      }}
    </div>

    <green-border-box data-tn="ssid">
      {{ $store.state.wifiStatus.ssid }}
    </green-border-box>

    <div class="wifi-status">
      <WifiOkSVG v-if="$store.state.wifiStatus.connected" data-tn="ok" />
      <WifiNoGoodSVG v-else data-tn="no-good" />
    </div>

    <div class="divider" />

    <div>
      <a
        href="//public-res.s3.ap-southeast-2.amazonaws.com/0339_A1_Changing+Wi-Fi+credentials.pdf"
        target="_blank"
      >
        <a-row class="green-box" type="flex" align="middle">
          <a-col :span="6">
            <InfoCentreSVG />
          </a-col>
          <a-col :span="18"> How to change Wi-Fi network </a-col>
        </a-row>
      </a>
    </div>

    <back-button />
  </div>
</template>

<script>
import InfoCentreSVG from "../assets/svg/info-centre.svg";
import WifiOkSVG from "../assets/svg/wifi-ok.svg";
import WifiNoGoodSVG from "../assets/svg/wifi-no-good.svg";

export default {
  components: { InfoCentreSVG, WifiOkSVG, WifiNoGoodSVG },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .wifi-status {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .divider {
    border-top: 1px solid #d5d5d5;
    height: 0;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .green-box {
    background: rgba(229, 248, 240, 0.6);
    height: 97px;
    box-shadow: rgba(162, 162, 162, 0.52) 0.5px 2.3px 4.4px;
    color: #007440;
    font-size: 15px;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 30px;
    line-height: 19px;
  }
}
</style>
