var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('p', [_vm._v(" There must be grid voltage measured at the assigned SolShare input terminal. ")]), _c('p', [_vm._v(" Measure for Voltage to Neutral of the incorrect connection. If zero, check all isolator state(s) between the SolShare and the grid connection point. ")]), _c('back-and-next', {
    staticClass: "troubleshoot",
    attrs: {
      "next-text": "Fixed? Re-try commissioning",
      "data-tn": "retry",
      "next-on-click": _vm.tryAgainClicked
    }
  }, [_vm._v(" Fixed? Re-try commissioning ")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('b', [_vm._v("How to fix a connection with no voltage reference ")])]);

}]

export { render, staticRenderFns }