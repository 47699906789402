<template>
  <div class="content">
    <back-button />
    <div class="title">
      Edit Building Address <BlackISVG @click="note1Visible = true" />
    </div>

    <a-form-model ref="form" :colon="false" :rules="rules" :model="form">
      <a-form-model-item prop="solshareName">
        <template #label>
          Solshare Name <BlackISVG @click="note2Visible = true" />
        </template>
        <a-input
          v-model.trim="form.solshareName"
          data-tn="solshare-name-input"
          size="large"
          class="input-box"
          placeholder="e.g. Solshare 1"
        />
      </a-form-model-item>

      <a-form-model-item label="Address" prop="address">
        <google-autocomplete
          id="address-input"
          v-model.trim="form.address"
          classname="input-box"
          :country="Object.values(COUNTRY_CODE)"
          :enable-geolocation="true"
          @placechanged="placeChanged"
          @blur="placeInputBlurred"
        >
        </google-autocomplete>
      </a-form-model-item>

      <a-form-model-item v-if="showCountryInput" prop="country" label="Country">
        <a-select
          v-model.trim="form.country"
          size="large"
          class="input-box"
          placeholder="Please input country"
          data-tn="system-building-address-country"
        >
          <a-select-option
            v-for="(code, country) in COUNTRY_CODE"
            :key="code"
            :value="code"
          >
            {{ country }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>

    <a-row type="flex" align="middle">
      <a-col :span="12">
        <back-button :style="{ marginTop: 0 }" />
      </a-col>
      <a-col :span="12">
        <yellow-button
          data-tn="building-address-next"
          :loading="loading"
          :disabled="isSaveDisabled"
          @click="saveClicked"
        >
          SAVE
        </yellow-button>
      </a-col>
    </a-row>

    <yellow-modal v-model="note1Visible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        Only enter the main building address details. Do not include any
        individual unit details here.
      </div>
    </yellow-modal>

    <yellow-modal v-model="note2Visible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        This is a descriptive label/name for each SolShare - use this if you
        would like to distinguish the SolShare between others installed on site.
        Examples include "SolShare 1", "SolShare closest to the MSB", "SolShare
        for Building A", etc.
      </div>
    </yellow-modal>
  </div>
</template>

<script>
import BlackISVG from "../assets/svg/black-i-filled.svg";
import { COUNTRY_CODE } from "../util/constants";

export default {
  components: {
    BlackISVG,
  },
  data() {
    return {
      COUNTRY_CODE,
      note1Visible: false,
      note2Visible: false,
      loading: false,
      form: {
        solshareName: this.$store.state.systemInfo.meter.alias,
        address: this.$store.state.systemInfo.meter.project.address,
        latitude: null,
        longitude: null,
        country: this.$store.state.systemInfo.meter.project.country,
      },
      rules: {
        address: [
          {
            required: true,
            message: "please input address",
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: "please select country",
            trigger: "blur",
          },
        ],
      },
      showCountryInput: false,
      isSaveDisabled: false,
    };
  },
  methods: {
    saveClicked() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          await this.$http.post(
            this.$api.updateBuildingAddress(
              this.$store.state.project.serialNumber
            ),
            this.form
          );
          await this.$store.dispatch("getSystemInfo", {
            serial: this.$store.state.project.serialNumber,
            redirect: false,
          });
          this.loading = false;
          await this.$router.go(-1);
        }
      });
    },
    placeInputBlurred() {
      this.isSaveDisabled = true;
      // this will be triggered first. when user selects an option from Google API
      // then cleared the input box and input something manually,
      // these values will need to be cleared
      this.form.latitude = null;
      this.form.longitude = null;
      this.form.country = null;

      // this delay will ensure the following code is triggered after
      // the potential placeChanged event
      setTimeout(() => {
        if (!this.form.country) {
          this.showCountryInput = true;
        }
        this.isSaveDisabled = false;
      }, 500);
    },
    placeChanged({ latitude, longitude, country }) {
      this.form.latitude = latitude;
      this.form.longitude = longitude;
      this.form.country = COUNTRY_CODE[country];
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    line-height: 38px;
    margin-top: 20px;
    margin-bottom: 27px;
  }
}
</style>
