var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "p"
  }, [_vm._v("How to troubleshoot a reversed CT.")]), _vm._m(0), _c('div', {
    staticClass: "p"
  }, [_vm._v(" Connection to check: "), _c('ul', {
    staticClass: "p"
  }, _vm._l(_vm.CTReversedUnits, function (unit, i) {
    return _c('li', {
      key: i
    }, [_vm._v(_vm._s(unit))]);
  }), 0)]), _c('back-and-next', {
    attrs: {
      "next-text": "Next - CT Installation",
      "to": "/commission/error/reversed-ct-au/2",
      "data-tn": "reversed-ct-au/1"
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p"
  }, [_vm._v(" Each CT must be "), _c('ul', {
    staticClass: "p"
  }, [_c('li', [_vm._v("Clipped in the correct orientation")]), _c('li', [_vm._v("The correct polarity at the SolShare connector")])])]);

}]

export { render, staticRenderFns }