<template>
  <div class="content">
    <p>
      <WarningSVG class="warning" />
      <b>WARNING:</b> The SolShare has detected outputs that have been wired to
      the incorrect phase.
    </p>
    <p>
      Please test all voltages at SolShare terminal blocks to ensure correct
      phasing.
    </p>
    <p>Any miswiring has potential to damage SolShare and void warranty.</p>
    <p>
      Click through for troubleshooting guidance on how to check all phase
      connections
    </p>

    <yellow-button
      class="troubleshoot"
      to="/commission/error/phase-to-phase-au"
    >
      Troubleshoot
    </yellow-button>
  </div>
</template>

<script>
import WarningSVG from "../../../../assets/svg/warning-yellow.svg";
export default {
  components: { WarningSVG },
};
</script>

<style scoped lang="less">
.content {
  margin-top: 30px;

  .warning {
    margin-right: 10px;
    width: 50px;
  }

  .troubleshoot {
    margin-top: 100px;
  }
}
</style>
