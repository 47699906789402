<template>
  <div class="content">
    <div class="p">
      <b>At the point of CT install</b> check that each CT is
      <ul class="p">
        <li>Clipped around the correct cable for that connection</li>
        <li>
          Clipped on the live meter tail <b>before</b> the solar connection
          (grid side)
        </li>
      </ul>
    </div>

    <img src="../../../../../assets/png/ct-point-of-connection-uk.png" />

    <div class="p">
      Connection to check:
      <ul class="p">
        <li v-for="(unit, i) in CTNotReadingUnits" :key="i">{{ unit }}</li>
      </ul>
    </div>

    <back-and-next
      next-text="Next - CT Installation"
      to="/commission/error/ct-not-reading-uk/3"
      data-tn="ct-not-reading-uk/2"
    >
    </back-and-next>
  </div>
</template>

<script>
import { CommissionErrorMixin } from "../../../../../mixins/commissionError";

export default {
  mixins: [CommissionErrorMixin],
};
</script>

<style scoped lang="less">
.content {
  .p {
    margin-top: 20px;
  }

  img {
    width: 100%;
  }
}
</style>
