<template>
  <div class="content">
    <p>
      <b>How to fix a connection with no voltage reference</b>
    </p>
    <p>
      Please ensure all isolation points are switched on and the flat is grid
      connected (if pre-paid meter ensure there is credit on meter)
    </p>
    <p>
      To isolate location of issue, measure voltage: At SolShare flat inputs to
      earth. Maintenance isolation board MCBs to earth.
    </p>

    <back-and-next
      class="troubleshoot"
      next-text="Fixed? Re-try commissioning"
      data-tn="retry"
      :next-on-click="tryAgainClicked"
    >
      Fixed? Re-try commissioning
    </back-and-next>
  </div>
</template>

<script>
import { CommissionErrorMixin } from "../../../../mixins/commissionError";

export default {
  mixins: [CommissionErrorMixin],
};
</script>

<style scoped lang="less">
.content {
  margin-top: 30px;
}
</style>
