<template>
  <div class="content">
    <p>
      <b>How to check the phase wiring at the SolShare. </b>
    </p>
    <ol>
      <li>Ensure SolShare cover is open and secure</li>
      <li>Set multi-meter to measure AC voltage</li>
      <li>Hold one probe on a connection wired to L1 phase</li>
      <li>Hold the other probe to another connection on L1</li>
      <li>
        Observe the voltage reading. It should read 0V.
        <b>If this is not 0V then you may have a phase miswiring</b>
      </li>
      <li>Repeat this for all connections across the same phase (L1)</li>
      <li>
        Similarly, repeat steps 3 - 6 for connections across L2 and L3 phases
      </li>
    </ol>

    <img
      src="../../../../assets/png/phase-phase-measurement.png"
      class="measurement"
    />

    <back-and-next
      class="troubleshoot"
      next-text="Fixed? Re-try commissioning"
      data-tn="retry"
      :next-on-click="tryAgainClicked"
    >
      Fixed? Re-try commissioning
    </back-and-next>
  </div>
</template>

<script>
import { CommissionErrorMixin } from "../../../../mixins/commissionError";

export default {
  mixins: [CommissionErrorMixin],
};
</script>

<style scoped lang="less">
.content {
  margin-top: 30px;

  .measurement {
    width: 100%;
  }
}
</style>
