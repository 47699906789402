var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('p', [_vm._v(" We have detected that there are problems with the voltage readings of this SolShare. ")]), _c('p', [_vm._v("The phase(s) below are not reading voltage.")]), _c('ul', _vm._l(_vm.conName, function (con, idx) {
    return _c('li', {
      key: idx
    }, [_vm._v(_vm._s(con))]);
  }), 0), _c('p', [_vm._v("Click through for guidance on how to check each connection.")]), _c('yellow-button', {
    staticClass: "troubleshoot",
    attrs: {
      "to": "/commission/error/no-voltage-au",
      "data-tn": "retry"
    }
  }, [_vm._v(" Troubleshoot ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }