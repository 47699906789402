var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('p', [_c('WarningSVG', {
    staticClass: "warning"
  }), _c('b', [_vm._v("WARNING:")]), _vm._v(" The SolShare has detected outputs that have been wired to the incorrect phase. ")], 1), _c('p', [_vm._v(" Please test all voltages at SolShare terminal blocks to ensure correct phasing. ")]), _c('p', [_vm._v("Any miswiring has potential to damage SolShare and void warranty.")]), _c('p', [_vm._v(" Click through for troubleshooting guidance on how to check all phase connections ")]), _c('yellow-button', {
    staticClass: "troubleshoot",
    attrs: {
      "to": "/commission/error/phase-to-phase-au"
    }
  }, [_vm._v(" Troubleshoot ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }